// noinspection HtmlDeprecatedAttribute

"use strict";

var myConsole = function(pluginName){
    return {
        prepareArgs: function () {
            if(typeof arguments[0] === 'string'){
                arguments[0] = '['+pluginName+']: ' + arguments[0];
            }
            return arguments;
        },
        log: function () {
            window.console.log.apply(window.console, this.prepareArgs.apply(console, arguments));
        },
        error: function () {
            window.console.error.apply(window.console, this.prepareArgs.apply(console, arguments));
        }
    };
};

$.fn.openLoadedFiles = function(){
    $(this).on('click', function (e) {
        if(!$.fancybox.isOpen && $(this).data('openType') == 'browser'){

            e.preventDefault();
            $.fancybox($.extend({}, CONFIG.FANCYBOX, {
                width: 1000,
                href: $(this).attr('href'),
                wrapCSS: "styled-fancybox overflow-visible background-white",
                type: 'iframe'
            }));

        } else if ($(this).data('openType') == 'browser' || $(this).data('openType') == 'download') {

            var tmp = $(this).attr('href').split('/');
            $(this).attr('download', tmp[tmp.length - 1]);

        } else if ($(this).data('openType') == 'image') {

            e.preventDefault();
            $(this).bigPhoto('open');

        }
    });
};

$.fn.myLoadFile = function(options){
    if(!options) options = {};
    var default_options = {
        beforeLoad: false,
        afterLoad: false,
        onError: function(){

        },
        inputName: 'uploaded_image[]'
    };
    var params = $.extend(true, {}, default_options, options),
        form = $(this);

    form.on('change','input[type=file]',function(){

        if(params.beforeLoad) params.beforeLoad();

        var iframe = $("<iframe id='upload_image_iframe' name='upload_image_iframe' style='display: none;'></iframe>"),
            input = $(this);

        iframe.appendTo($("body")).on('load', function () {
            var response = $(this).contents().find("body").text();

            try {
                var data = JSON.parse(response);
                if (data.error)
                {
                    params.onError();
                    message.error(data.error.replace("\\r\\n","\r\n","g"));
                }
                else if(data.success) {
                    filesLoaded(data);
                }
                else {
                    params.onError();
                    message.error(CONFIG.LANG.fileNotLoaded);
                }
            }
            catch(e) {
                params.onError();
                console.error(e);
                message.error(CONFIG.LANG.fileNotLoaded);
            }

            setTimeout(function(){
                iframe.remove();
            },1000);

            input.val(null);

            return false;
        });

        form.attr('target', 'upload_image_iframe').submit();

    }).on('dragenter', function(){
        $(this).addClass('dragenter');
    }).on('dragleave', function(){
        $(this).removeClass('dragenter');
    });

    function filesLoaded(data){
        var contin = true;

        if(params.afterLoad)
            contin = params.afterLoad(data);

        if(contin && data.files) {
            for (var i = 0; i < data.files.length; i++) {
                var file = data.files[i],
                    $loadedImage;
                form.after('<input type="hidden" name="' + params.inputName + '" value="' + file.id + '" />');

                if (file.thumb) {
                    var fileName = file.path ? file.path.split('/').pop() : '';
                    $loadedImage = $('<a class="loaded-image" target="_blank" ' +
                        'data-open-type="' + (file.openType || '') + '" ' +
                        'data-file-id="' + file.id + '" href="' + file.path + '" ' +
                        'title="'+fileName+'">' +
                        (file.creation_time ? ('<span class="file-creation-date">' + file.creation_time + '</span>') : '') +
                        '<i class="fa fa-times delete-file" title="Удалить файл"></i>' +
                        '<img class="thumb-image" src="' + file.thumb + '" data-original-src="' + data.path + '" data-file-id="' + file.id + '">' +
                        '</a>');

                    $loadedImage.openLoadedFiles();

                    form.before($loadedImage)
                }
            }
            if (form.children('.thumb').length) {
                form.addClass('filled');
            }
        }
    }

};

$.fn.keyIsNumber = function(callback){
    $('body').on('keydown',$(this).selector,function(e){
        var k = e.keyCode;
        if(!( (k >= 49 && k <= 57) ||
                (k >= 96 && k <= 105) ||
                k == 110 ||
                k == 144 ||
                k == 48 ||
                k == 188 ||
                k == 190 ||
                k == 13 ||
                k == 16 ||
                k == 17 ||
                k == 37 ||
                k == 39 ||
                k == 8 ||
                k == 46 ||
                k == 27 ||
                k == 116 ||
                k == 9
            )){
            e.preventDefault();
            return false;
        } else {
            if(callback) callback.call(this,e);
        }
        return true;
    });
    return $(this);
};

$.fn.mdlTable = function(options){
    if(!options) options = {};
    var default_options = {

    };
    var params = $.extend(true, {}, default_options, options),
        $table = $(this);

    return {
        /**
         * метод собирает айдишники из аттрибутов data-id выбранных строк: <tr data-id="{{ user.id }}">
         * @returns {Array}
         */
        getSelected: function(){
            var ids = [];

            $table.find('tr.is-selected').each(function(){
                ids.push($(this).data('id') || $(this).attr('id'))
            });

            return ids;
        }
    };
};

$.fn.mySubmitForm = function(options){
    if(!options) options = {};
    var default_options = {
        successMessage: null,
        disableButton: true,
        onSuccess: function(){

        },
        onSubmit: false,
        onError: function(data){
            message.error(data.error);
        },
        setError: function(){
            console.log($(this),'setError');
        },
        toError: function(){
            $(window).scrollTo({
                top:$ (this).offset().top,
                left: 0
            }, 500)
        }
    };
    default_options.onSuccess = function(data){
        message.error(options.successMessage || default_options.successMessage);
    };

    var params = $.extend(true, {}, default_options, options);

    var validate = function(){
        var error = false;

        $(this).find('input, select, textarea').each(function(){
            if($(this).attr('required') !== undefined){
                if(!$(this).val()){
                    params.setError.call(this);
                    if(!error){
                        var target = $(this).attr('type')=='hidden' ? $(this).parents(':visible:eq(0)') : this;
                        params.toError.call(target)
                    }
                    error = true;
                }
            }
        });

        return !error;
    };

    var submitAction = function(form, autoSubmit){
        var button = form.find('button[type=submit]'),
            url = form.data("submitAction"),
            data = form.unserialize();

        if(autoSubmit){
            data.autoSubmit = 1;
        }

        if(params.disableButton) {
            if (button.hasClass('disabled')) return false;
            button.addClass('disabled');
        }

        if(validate.call(form)){

            if(params.onSubmit){
                data = params.onSubmit.call(this, data);
            }

            if(data)
                $.post(url, data, function(data){
                    if(data){
                        if(autoSubmit){
                            data.autoSubmit = 1;
                        }
                        if(!data.error){
                            params.onSuccess(data);
                        }
                        else {
                            params.onError(data);
                        }
                    }
                    button.removeClass('disabled');
                }, 'json').fail(function(response){
                    button.removeClass('disabled');
                    params.onError(data);
                    showResponseError(response);
                });
            else {
                button.removeClass('disabled');
            }
        } else {
            button.removeClass('disabled');
        }
    };

    $(this).each(function(){
        var form = $(this);

        form.on('click', 'button[type=submit]', function(e){
            e.preventDefault();
            submitAction(form);
        });
        form.on('custom-submit', function(e){
            e.preventDefault();
            submitAction(form, true);
        });
    });

    return $(this);
};

$.fn.myLoading =  function(options){
    if(!options) options = {};
    var default_options = {
        type: 'cube',
        selector: '.my-spinner'
    };
    var params = $.extend(true, {}, default_options, options),
        $this = $(this),
        progressElement;

    progressElement = $this.children(params.selector);
    if(!progressElement.length){
        if(params.type === 'cube') {
            progressElement = $('<div class="my-spinner"><div class="sk-spinner sk-spinner-cube-grid">' +
                '<div class="sk-cube"></div>' +
                '<div class="sk-cube"></div>' +
                '<div class="sk-cube"></div>' +
                '<div class="sk-cube"></div>' +
                '<div class="sk-cube"></div>' +
                '<div class="sk-cube"></div>' +
                '<div class="sk-cube"></div>' +
                '<div class="sk-cube"></div>' +
                '<div class="sk-cube"></div>' +
                '</div></div>');
        } else if (params.type === 'double-bounce'){
            progressElement = $('<div class="my-spinner"><div class="sk-spinner sk-spinner-double-bounce">' +
                '<div class="sk-double-bounce1"></div>' +
                '<div class="sk-double-bounce2"></div>' +
                '</div></div>');
        }
        $this.append(progressElement);
    }

    return {
        show: function () {
            progressElement.addClass('active');
        },
        hide: function () {
            progressElement.removeClass('active');
        }
    };
};

$.fn.unserialize = function(){
    return $.unserialize($(this).find(':input').serialize());
};
$.unserialize = function(serializedString){
    var str = decodeURI(serializedString.replace(/\+/g," "));
    var pairs = str.split('&');
    var obj = {}, p, idx, val;
    for (var i=0, n=pairs.length; i < n; i++) {
        p = pairs[i].split('=');
        idx = p[0];

        if (idx.indexOf("[]") == (idx.length - 2)) {
            var ind = idx.substring(0, idx.length-2);
            if (obj[ind] === undefined) {
                obj[ind] = [];
            }
            obj[ind].push(p[1]);
        }
        else {
            obj[idx] = p[1];
        }
    }
    return obj;
};

$.fn.bindWithDelay = function( type, data, fn, timeout, throttle ) {

    if ( $.isFunction( data ) ) {
        throttle = timeout;
        timeout = fn;
        fn = data;
        data = undefined;
    }

    // Allow delayed function to be removed with fn in unbind function
    fn.guid = fn.guid || ($.guid && $.guid++);

    // Bind each separately so that each element has its own delay
    return this.each(function() {

        var wait = null;

        function cb() {
            var e = $.extend(true, { }, arguments[0]);
            var ctx = this;
            var throttler = function() {
                wait = null;
                fn.apply(ctx, [e]);
            };

            if (!throttle) { clearTimeout(wait); wait = null; }
            if (!wait) { wait = setTimeout(throttler, timeout); }
        }

        cb.guid = fn.guid;

        $(this).bind(type, data, cb);
    });
};

$.fn.chosenAjaxify = function(options){
    if(!options) options = {};
    var default_options = {
        url: '',
        dataCallback: function(){
            return {};
        },
        afterUpdateCallback: function(){

        },
        createOption: null
    };
    var params = $.extend(true, {}, default_options, options),
        $this = $(this),
        id = $this.attr('id'),
        REQUEST = {};

    if(!$this.length)
        return false;

    var div_id = id;
    div_id = div_id.split("-").join("_");
    // if single
    if($('div#' + div_id + '_chosen').hasClass('chosen-container-single')){
        $('div#' + div_id + '_chosen' + ' .chosen-search input').bindWithDelay('keyup', function(event){
            // ignore arrow key
            if(event.keyCode >= 37 && event.keyCode <= 40){
                return null;
            }
            // ignore enter
            if(event.keyCode == 13){
                return null;
            }
            // abort previous ajax
            if(REQUEST[id] != null){
                REQUEST[id].abort();
            }
            // get keyword and build regex pattern (use to emphasis search result)
            var keyword = $('div#' + div_id + '_chosen' + ' .chosen-search input').val();
            var keyword_pattern = new RegExp(keyword, 'gi');
            // remove all options of chosen
            $('div#' + div_id + '_chosen ul.chosen-results').empty();
            // remove all options of original select
            $("#"+id).empty();
            var data = params.dataCallback();
            data['search'] = keyword;
            REQUEST[id] = $.ajax({
                type: 'POST',
                data: data,
                url: params.url,
                dataType: "json",
                success: function(response){
                    // map, just as in functional programming :). Other way to say "foreach"
                    // add new options to original select
                    $('#'+id).append('<option value=""></option>');
                    $.map(response, function(item){
                        if(params.createOption){
                            $('#'+id).append(params.createOption(item));
                        } else {
                            $('#'+id).append('<option value="' + item.value + '">' + item.label + '</option>');
                        }
                    });
                },
                complete: function(){
                    keyword = $('div#' + div_id + '_chosen' + ' .chosen-search input').val();
                    // update chosen
                    $("#"+id).trigger("chosen:updated");
                    // some trivial UI adjustment

                    var chosenBox = $('div#' + div_id + '_chosen');

                    chosenBox.removeClass('chosen-container-single-nosearch');

                    chosenBox.find('.chosen-search input').val(keyword);
                    chosenBox.find('.chosen-search input').removeAttr('readonly');
                    chosenBox.find('.chosen-search input').focus();
                    // emphasis keywords
                    chosenBox.find('.active-result').each(function(){
                        // var html = $(this).html();
                        // $(this).html(html.replace(keyword_pattern, function(matched){
                        //     return '<em>' + matched + '</em>';
                        // }));
                    });

                    params.afterUpdateCallback(chosenBox);
                }
            }, 500);
        });
    } else if($('div#' + div_id + '_chosen').hasClass('chosen-container-multi')){ // if multi
        $('div#' + div_id + '_chosen' + ' input').bindWithDelay('keyup', function(event){
            // ignore arrow key
            if(event.keyCode >= 37 && event.keyCode <= 40){
                return null;
            }
            // ignore enter
            if(event.keyCode == 13){
                return null;
            }
            if(REQUEST[id] != null){
                REQUEST[id].abort();
            }
            var old_input_width = $('div#' + div_id + '_chosen' + ' input').css('width');
            // get keyword and build regex pattern (use to emphasis search result)
            var keyword = $(this).val();
            var keyword_pattern = new RegExp(keyword, 'gi');
            // old values and labels
            var old_values = [], old_value;
            var old_labels = [], old_label;
            $('#'+id+' option:selected').each(function(){
                old_value = $(this).val();
                old_label = $(this).html();
                old_values.push(old_value);
                old_labels.push(old_label);
            });
            // remove all options of chosen
            $('div#' + div_id + '_chosen ul.chosen-results').empty();
            $("#"+id).empty();
            var data = params.dataCallback();
            data['search'] = keyword;
            REQUEST[id] = $.ajax({
                type: 'POST',
                data: data,
                url: params.url,
                dataType: "json",
                success: function(response){
                    // add the old selected options
                    for(var i=0; i<old_values.length; i++){
                        var value = old_values[i];
                        var label = old_labels[i];
                        $('#'+id).append('<option selected value="' + value + '">' + label + '</option>')
                    }
                    // map, just as in functional programming :). Other way to say "foreach"
                    $.map(response, function(item){
                        // this is ineffective, is there any "in" syntax in javascript?
                        var found = false;
                        for(i=0; i<old_values[i]; i++){
                            if(old_values[i] == item.value){
                                found = true;
                                break;
                            }
                        }
                        if(!found){
                            if(params.createOption){
                                $('#'+id).append(params.createOption(item));
                            } else {
                                $('#'+id).append('<option value="' + item.value + '">' + item.label + '</option>');
                            }
                        }
                    });
                },
                complete: function(response){
                    keyword = $('div#' + div_id + '_chosen' + ' input').val();
                    $("#"+id).trigger("chosen:updated");

                    var chosenBox = $('div#' + div_id + '_chosen');

                    chosenBox.removeClass('chosen-container-single-nosearch');
                    chosenBox.find('input').val(keyword);
                    chosenBox.find('input').removeAttr('readonly');
                    chosenBox.find('input').css('width', old_input_width);
                    chosenBox.find('input').focus();
                    // put that underscores
                    chosenBox.find('.active-result').each(function(){
                        var html = $(this).html();
                        $(this).html(html.replace(keyword_pattern, function(matched){
                            return '<em>' + matched + '</em>';
                        }));
                    });

                    params.afterUpdateCallback(chosenBox);
                }
            });
        }, 500);
    }
};

$.fn.flowMoney = function(options){
    var default_options = {
            type: 'in',
            obj_out: null,
            add: 0,
            newNumber: 0,
            format: false
        },
        params,
        $this = $(this);

    if(typeof options === "number"){
        default_options.add = options;
        params = default_options;
    } else {
        params = $.extend(true, {}, default_options, options);
    }

    if(params.add === 0 && params.newNumber){
        var currentValue = parseFloat($this.text().replace(/\s/,''));
        params.add = parseFloat(params.newNumber) - currentValue;
    }

    var type = params.type,
        iter = 201,
        shag = (type === 'out-in' || type === 'out-out') ? parseFloat(params.obj_out.val()) / iter : params.add / iter,
        plus = (type === 'out-in' || type === 'out-out') ? parseFloat(params.obj_out.val()) : params.add,
        start_value = parseFloat($this.text().replace(/\s/,'')),
        current_value = parseFloat($this.text().replace(/\s/,'')),
        curent_input = plus,
        newVal,
        t = 10;

    for(var i = 0; i < iter; i++){
        t = t + 5;
        if(i < (iter - 1))
            setTimeout(function(){
                current_value = parseFloat(current_value + (type === 'out-out' ? -shag : shag));
                newVal = parseFloat(current_value).toFixed(2);
                if(params.format){
                    newVal = number_format(newVal, 2, '.', ' ');
                } else {
                    newVal = number_format(newVal, 0, '.', ' ');
                }
                $this.text(newVal);
                if(type === 'out-in' || type === 'out-out'){
                    curent_input = curent_input - shag;
                    params.obj_out.val(parseFloat(curent_input).toFixed(2));
                }
            },t);
        else
            setTimeout(function(){
                newVal = parseFloat(start_value + (type === 'out-out' ? - plus : plus)).toFixed(2);
                if(params.format){
                    newVal = number_format(newVal, 2, '.', ' ');
                } else {
                    newVal = number_format(newVal, 0, '.', ' ');
                }
                $this.text(newVal);
                if(type === 'out-in' || type === 'out-out'){
                    params.obj_out.val('');
                }
            },t)
    }

    return $(this);
};

$.fn.myAutocomplete = function(options){
    var default_options = {
            url: null,
            data: function(data){return data},
            onEmptyResults: function(){},
            onLoadResults: function(){},
            onSelect: function(){},
        },
        params,
        $this = $(this);

    params = $.extend(true, {}, default_options, options);

    $(this).each(function(){
        var $el = $(this);
        $el.autocomplete({
            appendTo: $(this).parent(),
            source: function(request, callback){
                var data = params.data({});
                $.post(params.url, data, function(data){
                    if(!data || !data.length){
                        params.onEmptyResults();
                    } else {
                        params.onLoadResults();
                    }
                    callback(data)
                });
            },
            delay: 100,
            minLength: 0,
            open: function(){

            },
            focus: function(event, ui){

            },
            close: function(event, ui){
                if(!$el.val()){

                }
            },
            select: params.onSelect
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            return $("<li></li>")
                .data("item.autocomplete", item)
                .append("<div>"+item.label || item+"</div>")
                .appendTo(ul);
        };
    });

    return $(this);
};

/** $el.mySpinner */
(function($){

    "use strict";

    var pluginName = 'mySpinner';

    var methods = {
        init: function(container, params, options){

            console.log('['+pluginName+']: init');

            params = $.extend(true,{
                spinnerBox: null,
                spinner: null,
                background: '',
                spinnerOptions: {
                    lines: 17, // The number of lines to draw
                    length: 33, // The length of each line
                    width: 5, // The line thickness
                    radius: 23, // The radius of the inner circle
                    scale: 1, // Scales overall size of the spinner
                    corners: 1, // Corner roundness (0..1)
                    color: '#388E3C', // #rgb or #rrggbb or array of colors
                    opacity: 0.25, // Opacity of the lines
                    rotate: 0, // The rotation offset
                    direction: 1, // 1: clockwise, -1: counterclockwise
                    speed: 1, // Rounds per second
                    trail: 60, // Afterglow percentage
                    fps: 20, // Frames per second when using setTimeout() as a fallback for CSS
                    zIndex: 2e9, // The z-index (defaults to 2000000000)
                    className: 'spinner', // The CSS class to assign to the spinner
                    top: '50%', // Top position relative to parent
                    left: '50%', // Left position relative to parent
                    shadow: false, // Whether to render a shadow
                    hwaccel: false, // Whether to use hardware acceleration
                    position: 'absolute' // Element positioning
                }
            }, options);

            container.data()[pluginName] = {
                params: params
            };

            if(!params.spinnerBox){
                methods.create(container, params);
            }

            methods.show(container, params);
        },
        create: function(container, params){
            params.spinnerBox = $('<div>', {
                'class': 'my-spinner-box'
            });

            if(params.background){
                params.spinnerBox.css('background', params.background);
            }

            container.append(params.spinnerBox);

            // noinspection JSUnresolvedFunction
            params.spinner = new Spinner(params.spinnerOptions).spin(params.spinnerBox[0]);
        },
        show: function (container, params) {
            if(params.spinnerBox)
                params.spinnerBox.show();
        },
        hide: function (container, params) {
            if(params && params.spinnerBox)
                params.spinnerBox.hide();
        }
    };

    $[pluginName] = $.fn[pluginName] = function( method ) {

        //добавляем первые параметры: container, params
        var args = [$(this), $(this).data()[pluginName] && $(this).data()[pluginName].params];

        if (methods[method]) {

            //добавляет к аргументам те, которые были переданы
            args = args.concat(Array.prototype.slice.call(arguments, 1));
            return methods[method].apply(this, args);

        } else if ( typeof method === 'object' || !method ) {

            //добавляет к аргументам те, которые были переданы
            args = args.concat(arguments[0]);
            return methods.init.apply(this, args );

        } else if (method.split('.').length > 1) {

            var tmp = method.split('.'), i, target = methods;
            for(i = 0; i < tmp.length; i++){
                if(!target[tmp[i]]) break;
                target = target[tmp[i]];
            }
            //добавляет к аргументам те, которые были переданы
            args = args.concat(Array.prototype.slice.call(arguments, 1));
            return target.apply(this, args);

        } else {
            $.error( 'Метод с именем ' +  method + ' не существует для jQuery.' + pluginName );
        }

    };

})(jQuery);

/** $.cart - модуль, реализующий основные методы для работы с корзиной клиента
 * */
(function($) {

    "use strict";

    var pluginName = 'cart';

    var console = myConsole(pluginName);

    var methods = {
        init: function (container, params, options) {

            console.log('init');

            params = $.extend(true, {
                stackChanges: {},
                stackSets: {},
                data: []
            }, options);

            container.data()[pluginName] = {
                params: params
            };
        },
        add: function(container, params, id, count, callback){
            methods.setToStackChanges(container, params, id, count);
            methods.sync(container, params, callback);
        },
        set: function(container, params, id, count, store, callback){
            methods.setToStackSets(container, params, id, count, store);
            methods.sync(container, params, callback);
        },
        setToStackChanges: function(container, params, productId, count, storeId){
            if (typeof storeId !== 'undefined') {
                if(params.stackChanges[productId]){
                    params.stackChanges[productId] = {count: params.stackChanges[productId].count + count, store: storeId};
                } else {
                    params.stackChanges[productId] = {count: count, store: storeId};
                }
            } else {
                if(params.stackChanges[productId]){
                    params.stackChanges[productId] = params.stackChanges[productId] + count;
                } else {
                    params.stackChanges[productId] = count;
                }
            }
        },
        setToStackSets: function(container, params, productId, count, storeId, noReplace){
            if(noReplace){
                if (typeof storeId !== 'undefined') {
                    if(typeof params.stackSets[productId] === 'undefined'){
                        params.stackSets[productId] = {count: count, store: storeId};
                    }
                } else {
                    if(typeof params.stackSets[productId] === 'undefined'){
                        params.stackSets[productId] = count;
                    }
                }
            } else {
                if (typeof storeId !== 'undefined') {
                    params.stackSets[productId] = {count: count, store: storeId};
                } else {
                    params.stackSets[productId] = count;
                }
            }
        },
        sync: $.debounce(function(container, params, callback, counter){

            if(!CONFIG.URL.cartSync){
                console.error('Not found "CONFIG.URL.cartSync" param');
                return false;
            }

            if(!counter) counter = 0;

            var queryParams = {
                cartChanges: params.stackChanges,
                cartSets: params.stackSets
            };

            params.stackChanges = {};
            params.stackSets = {};

            $.post(CONFIG.URL.cartSync, queryParams, function(data){
                if(data && data.message && data.message === 'success'){
                    methods.updateData(container, params, queryParams);

                    if(callback && typeof callback === 'function')
                        callback.call();
                }
            }, 'json').fail(function(){
                //возвращаем отправленные изменения обратно в стек, чтобы не потерять их
                var productId;
                for(productId in queryParams.cartChanges){
                    if(queryParams.cartChanges.hasOwnProperty(productId)){
                        if (typeof queryParams.cartChanges[productId].store !== 'undefined') {
                            methods.setToStackChanges(container, params, productId, queryParams.cartChanges[productId].count, queryParams.cartChanges[productId].store);
                        } else {
                            methods.setToStackChanges(container, params, productId, queryParams.cartChanges[productId]);
                        }
                    }
                }
                for(productId in queryParams.cartSets){
                    if(queryParams.cartSets.hasOwnProperty(productId)){
                        var countProduct = 0;
                        if (typeof queryParams.cartSets[productId].store !== 'undefined') {
                            countProduct = queryParams.cartSets[productId].count;
                        } else {
                            countProduct = queryParams.cartSets[productId];
                        }
                        methods.setToStackSets(container, params, productId, countProduct, queryParams.cartSets[productId].store, true);
                    }
                }
                //вызываем синхронизацию снова, но не более пяти раз
                if(counter++ < 5)
                    methods.sync(container, params, callback, counter);
            });
        }, 1000),
        updateData: function(container, params, queryParams){
            var productId, i, countProduct;
            var carts = methods.get(container, params);

            for(productId in queryParams.cartChanges){
                productId = parseInt(productId, 10);
                if(queryParams.cartChanges.hasOwnProperty(productId)){
                    countProduct = 0;
                    if (typeof queryParams.cartChanges[productId].store !== 'undefined') {
                        countProduct = queryParams.cartChanges[productId].count;
                    } else {
                        countProduct = queryParams.cartChanges[productId];
                    }
                    if (carts.hasOwnProperty(productId)) {
                        for (i = 0; i < params.data.length; i++) {
                            if (params.data[i].id === productId) {
                                params.data[i].count = countProduct;
                            }
                        }
                    } else {
                        params.data.push({
                            id: productId,
                            count: countProduct,
                        })
                    }
                }
            }
            for(productId in queryParams.cartSets){
                productId = parseInt(productId, 10);
                if(queryParams.cartSets.hasOwnProperty(productId)){
                    countProduct = 0;
                    if (typeof queryParams.cartSets[productId].store !== 'undefined') {
                        countProduct = queryParams.cartSets[productId].count;
                    } else {
                        countProduct = queryParams.cartSets[productId];
                    }
                    if (carts.hasOwnProperty(productId)) {
                        for (i = 0; i < params.data.length; i++) {
                            if (params.data[i].id === productId) {
                                params.data[i].count = countProduct;
                            }
                        }
                    } else {
                        params.data.push({
                            id: productId,
                            count: countProduct,
                        })
                    }
                }
            }
        },
        setData: function(container, params, data){
            params.data = data;
        },
        getData: function(container, params){
            return params.data;
        },
        get: function(container, params){
            var cart = {};
            for (var i = 0; i < params.data.length; i++) {
                var product = params.data[i];
                cart[product.id] = product.count;
            }
            return cart;
        }
    };

    $[pluginName] = function(method) {

        var container = typeof this === 'function' ? $(window) : $(this);
        //добавляем первые параметры: container, params
        var args = [container, container.data()[pluginName] && container.data()[pluginName].params];

        if (methods[method]) {

            if(!container.data()[pluginName] && method !== 'init'){
                methods.init(container, null, {})
            }

            args = [container, container.data()[pluginName] && container.data()[pluginName].params];

            //добавляет к аргументам те, которые были переданы
            args = args.concat(Array.prototype.slice.call(arguments, 1));
            return methods[method].apply(this, args);

        } else if ( typeof method === 'object' || !method ) {

            //добавляет к аргументам те, которые были переданы
            args = args.concat(arguments[0]);
            return methods.init.apply(this, args );

        } else if (method.split('.').length > 1) {

            var tmp = method.split('.'), i, target = methods;
            for(i = 0; i < tmp.length; i++){
                if(!target[tmp[i]]) break;
                target = target[tmp[i]];
            }
            //добавляет к аргументам те, которые были переданы
            args = args.concat(Array.prototype.slice.call(arguments, 1));
            return target.apply(this, args);

        } else {
            $.error( 'Метод с именем ' +  method + ' не существует для jQuery.' + pluginName );
        }

    };

})(jQuery);

/** $.myDropdown - модуль, реализующий стилизованный выпадающий мультиселект
 * */
(function($) {

    "use strict";

    var pluginName = 'myDropdown';

    var console = myConsole(pluginName);

    var methods = {
        init: function (container, params, options) {

            console.log('init');

            params = $.extend(true, {
                domObjects: {
                    wrapper: null
                },
                templates: {
                    wrapper: '<div class="my-dropdown"></div>',
                    item: '<div class="my-dropdown-item" data-value="{value}">' +
                        '<span>' +
                        '{label}' +
                        '</span>' +
                        '</div></div>'
                }
            }, options);

            container.data()[pluginName] = {
                params: params
            };

            if(!container.next('.my-dropdown').length){
                methods.draw(container, params);
            }

            methods.update(container, params);

            methods.setEvents(container, params);
        },
        draw: function(container, params){
            params.domObjects.wrapper = $(params.templates.wrapper);
            container.after(params.domObjects.wrapper);
        },
        update: function(container, params){

            var html = '';

            container.find('option').each(function () {

                var option = $(this),
                    name = container.attr('name').replace(/\[\]/g, ''),
                    label = option.text();

                html += params.templates.item
                    .replace(/\{name\}/g, name)
                    .replace(/\{value\}/g, option.attr('value'))
                    .replace(/\{index\}/g, option.index())
                    .replace(/\{label\}/g, label);
            });

            params.domObjects.wrapper.html(html);
        },
        setEvents: function(container, params){
            container.parent().parent().on('click', '.toggle-button', function (e) {
                e.preventDefault();
                if(params.domObjects.wrapper.is(':visible')) {
                    params.domObjects.wrapper.hide();
                } else {
                    params.domObjects.wrapper.show();
                    $('body').on('click', function (e) {
                        if ($(e.target).closest(params.domObjects.wrapper).length === 0) {
                            params.domObjects.wrapper.hide();
                        }
                    });
                }
            });
            params.domObjects.wrapper.on('click', '.my-dropdown-item', function(){
                var value = $(this).data('value');
                if(value) {
                    container.find('option').attr('selected',null).prop('selected', false);
                    var $option = container.find('option[value="' + value + '"]');
                    $option.attr('selected','selected').prop('selected', true);
                    var text = $option.text();
                    container.parent().parent().find('.toggle-button .order-field').text(text);
                }
                params.domObjects.wrapper.hide();
                $("#myOverlay").hide();
                container.trigger('change');
            });
        }
    };

    $[pluginName] = $.fn[pluginName] = function(method) {

        var container = typeof this === 'function' ? $(window) : $(this);

        if(!container || !container.length) return;

        //добавляем первые параметры: container, params
        var args = [container, container.data()[pluginName] && container.data()[pluginName].params];

        if (methods[method]) {

            if(!container.data()[pluginName] && method !== 'init'){
                methods.init(container, null, {})
            }

            args = [container, container.data()[pluginName] && container.data()[pluginName].params];

            //добавляет к аргументам те, которые были переданы
            args = args.concat(Array.prototype.slice.call(arguments, 1));
            return methods[method].apply(this, args);

        } else if ( typeof method === 'object' || !method ) {

            //добавляет к аргументам те, которые были переданы
            args = args.concat(arguments[0]);
            return methods.init.apply(this, args );

        } else if (method.split('.').length > 1) {

            var tmp = method.split('.'), i, target = methods;
            for(i = 0; i < tmp.length; i++){
                if(!target[tmp[i]]) break;
                target = target[tmp[i]];
            }
            //добавляет к аргументам те, которые были переданы
            args = args.concat(Array.prototype.slice.call(arguments, 1));
            return target.apply(this, args);

        } else {
            $.error( 'Метод с именем ' +  method + ' не существует для jQuery.' + pluginName );
        }

    };

})(jQuery);

/** $.myMulticheck- модуль, реализующий кастомный элемент мультивыбора
 * */
(function($) {

    "use strict";

    var pluginName = 'myMulticheck';

    var console = myConsole(pluginName);

    var methods = {
        init: function (container, params, options) {

            console.log('init');

            params = $.extend(true, {
                domObjects: {
                    selectAllInput: null,
                    allCheckboxes: null,
                    allItems: null,
                    searchInput: null
                },
                templates: {

                }
            }, options);

            container.data()[pluginName] = {
                params: params
            };

            methods.setDomObjects(container, params);
            methods.setEvents(container, params);
        },
        setDomObjects: function(container, params){
            if(params.domObjects.selectAllInput === null) params.domObjects.selectAllInput = container.find('.js-select-all-checkbox');
            if(params.domObjects.allCheckboxes === null) params.domObjects.allCheckboxes = container.find('.js-item-checkbox');
            if(params.domObjects.searchInput === null) params.domObjects.searchInput = container.find('.js-multicheck-search');
            if(params.domObjects.allItems === null) params.domObjects.allItems = container.find('.js-item');
        },
        setEvents: function(container, params){
            params.domObjects.selectAllInput.on('change', function(e){
                if($(this).prop('checked')){
                    params.domObjects.allCheckboxes.prop('checked', true).attr('checked','checked');
                } else {
                    params.domObjects.allCheckboxes.prop('checked', false).attr('checked',null);
                }
            });
            params.domObjects.allCheckboxes.on('change', function(e){
                //если есть невыбранный чекбокс - снимаем мектку "выбрать все"
                if(params.domObjects.allCheckboxes.filter(':not(:checked)').length > 0){
                    params.domObjects.selectAllInput.prop('checked', false).attr('checked',null);
                } else {
                    params.domObjects.selectAllInput.prop('checked', true).attr('checked','checked');
                }
            });
            params.domObjects.searchInput.on('keyup', function(e){
                const searchText = $(this).val().toLowerCase();
                const $list = $(this).closest('.multicheck').find('.checkboxes-multicheck-list__item.js-item');
                if (searchText) {
                    const $containsItems = $list.filter(function(){
                        const elementText = $(this).text().toLocaleLowerCase().trim();
                        return (new RegExp(searchText)).test(elementText);
                    });
                    $list.hide();
                    $containsItems.show();
                } else {
                    $list.show();
                }
            });
        },
        getSelected: function(container, params){
            var values = [];
            container.find('input:checked').each(function(){
                var val = $(this).val();
                if(val){
                    values.push(val);
                }
            });
            return values;
        }
    };

    $[pluginName] = $.fn[pluginName] = function(method) {

        var container = typeof this === 'function' ? $(window) : $(this);

        if(!container || !container.length) return;

        //добавляем первые параметры: container, params
        var args = [container, container.data()[pluginName] && container.data()[pluginName].params];

        if (methods[method]) {

            if(!container.data()[pluginName] && method !== 'init'){
                methods.init(container, null, {})
            }

            args = [container, container.data()[pluginName] && container.data()[pluginName].params];

            //добавляет к аргументам те, которые были переданы
            args = args.concat(Array.prototype.slice.call(arguments, 1));
            return methods[method].apply(this, args);

        } else if ( typeof method === 'object' || !method ) {

            //добавляет к аргументам те, которые были переданы
            args = args.concat(arguments[0]);
            return methods.init.apply(this, args );

        } else if (method.split('.').length > 1) {

            var tmp = method.split('.'), i, target = methods;
            for(i = 0; i < tmp.length; i++){
                if(!target[tmp[i]]) break;
                target = target[tmp[i]];
            }
            //добавляет к аргументам те, которые были переданы
            args = args.concat(Array.prototype.slice.call(arguments, 1));
            return target.apply(this, args);

        } else {
            $.error( 'Метод с именем ' +  method + ' не существует для jQuery.' + pluginName );
        }

    };

})(jQuery);

/** $.bannersGrid - модуль, реализующий редактируемую сетку баннеров
 * */
(function($) {

    "use strict";

    const pluginName = 'bannersGrid';

    const console = myConsole(pluginName);

    const methods = {
        init: function (container, params, options) {

            console.log('init');

            params = $.extend(true, {
                cellWidth: 360,
                cellHeight: 200,
                cellMargin: 10,
                imagesMargin: 5,
                imageFormPlaceholder: '',
                changeLinkText: '',
                nothingToSaveText: '',
                buttonSaveText: '',
                buttonCancelText: '',
                imageLoadUrl: '',
                saveChangesUrl: '',
                storesSelect: '',
                afterSave: null,
                countryId: null,
                domObjects: {
                    cellsBox: null,
                    mask: null,
                    imageForm: null,
                    buttonSave: null,
                    buttonCancel: null,
                },
                templates: {
                    cellsBox: '<div class="banners-grid__cells-wrapper js-cells-box"></div>',
                    cell: '<div class="banners-grid__cell-wrapper js-cell animated fadeInDown">' +
                        '<div class="banners-grid__cell">' +
                            '<i class="banners-grid__cell-icon banners-grid__icon-plus">+</i>' +
                            '<i class="fa fa-check banners-grid__cell-icon banners-grid__icon-check"></i>' +
                        '</div>' +
                    '</div>',
                    mask: '<div class="banners-grid__mask"></div>',
                    imageForm: '<form id="importArea" class="banners-grid__media-form animated fadeIn" action="{load_url}" method="post"' +
                        ' enctype="multipart/form-data">' +
                        '<input type="file" name="banner" accept="image/*,video/*">' +
                        '<input type="hidden" name="width">' +
                        '<input type="hidden" name="height">' +
                        '<input type="submit">' +
                        '<div class="placeholder"></div>' +
                        '<i class="fa-regular fa-file-image file-icon"></i>' +
                        '<div class="banners-grid__media-form-loader js-loader"></div>' +
                        '</form>',
                    image: '<div class="banners-grid__media js-media" data-file-id="{fileId}">' +
                        '<img src="{src}" alt="#banner">' +
                        '<button class="btn btn-success btn-xs change-link-button"><i class="fa fa-link"></i>&nbsp;&nbsp;&nbsp;<span class="bold">{changeLinkText}</span></button>' +
                        '<input class="link-input" type="text" name="link" value="">' +
                        '<div class="banners-grid__media-remove js-media-remove">×</div>' +
                        '</div>',
                    video: '<div class="banners-grid__media js-media banner-video" data-file-id="{fileId}">' +
                        '<video preload="auto" autoPlay loop muted>' +
                            '<source src="{src}" type="{type}">' +
                        '</video>' +
                        '<button class="btn btn-success btn-xs change-link-button"><i class="fa fa-link"></i>&nbsp;&nbsp;&nbsp;<span class="bold">{changeLinkText}</span></button>' +
                        '<input class="link-input" type="text" name="link" value="">' +
                        '<div class="banners-grid__media-remove js-media-remove">×</div>' +
                        '</div>',
                    buttonAddRow: `<div class="banners-grid__add-row-button">
                                        <a href="#" class="add-row-button"><i class="fa fa-plus-circle"></i></a>
                                   </div>`,
                    buttonSave: '<div class="btn btn-primary banners-grid__fixed-button banners-grid__button-save js-save-button">{buttonSaveText}</div>',
                    buttonCancel: '<div class="btn btn-default banners-grid__fixed-button banners-grid__button-cancel js-cancel-button">{buttonCancelText}</div>',
                }
            }, options);

            container.data()[pluginName] = {
                params: params
            };

            container.width(container.width());
            container.height(container.height());

            methods.draw(container, params);
            methods.setEvents(container, params);
        },
        setEvents: function(container, params) {
            container.on('click', '.js-cell', function() {
                $(this).toggleClass('banners-grid__cell-wrapper_selected');
                methods.drawMask(container, params);
                methods.checkLastRow(container, params);
            }).on('click', '.js-media-remove', function() {
                $(this).parent().fadeOut(function() {
                    $(this).remove();
                    methods.checkEmptyRows(container, params);
                    methods.checkLastRow(container, params);
                });
            }).on('click', '.change-link-button', function() {
                $(this).parent('.js-media').toggleClass('banners-grid__media_with-input');
            }).on('click', '.js-save-button', function(e) {
                e.preventDefault();

                const $button = $(this);
                if ($button.hasClass('disabled')) return false;
                $button.addClass('disabled');

                methods.saveChanges(container, params, function() {
                    $button.removeClass('disabled');
                });
            }).on('click', '.js-cancel-button', function() {
                location.reload();
            });
        },
        saveChanges: function(container, params, callback) {
            const banners = methods.getData(container, params);
            const stores = params.storesSelect ? params.storesSelect.val() : '';
            $.post(params.saveChangesUrl, {
                banners: banners,
                stores: stores,
                countryId: params.countryId,
            }, function(response) {
                params.afterSave && params.afterSave(response);
                callback && callback();
            },'json').fail(function(response) {
                showResponseError(response);
                callback && callback();
            });
        },
        draw: function(container, params) {
            if (params.domObjects.cellsBox === null) {
                params.domObjects.cellsBox = $(params.templates.cellsBox).appendTo(container);
            }

            const fullHeight = params.domObjects.cellsBox.height(),
                  rowsCount = Math.floor(fullHeight / methods.getRowHeight(container, params)),
                  countInRow = methods.getCountCellsInRow(container, params);

            if (rowsCount > 0) {
                container.find('.js-filler').remove();
                for (let i = 0; i < rowsCount; i++) {
                    methods.appendButtonAddRow(container, params, i);
                    for (let j = 0; j < countInRow; j++) {
                        methods.appendCell(container, params);
                    }
                }
            } else {
                console.error('calculate 0 rows for height='+fullHeight+' and cellHeight='+params.cellHeight);
            }
            setTimeout(function() {
                methods.checkLastRow(container, params);
            }, 500);
            methods.drawButtons(container, params);
        },
        drawButtons: function(container, params) {
            let template;
            if (params.domObjects.buttonSave === null) {
                template = params.templates.buttonSave
                    .replace('{buttonSaveText}', params.buttonSaveText);
                params.domObjects.buttonSave = $(template);
                params.domObjects.buttonSave.appendTo(container);
            }
            if (params.domObjects.buttonCancel === null) {
                template = params.templates.buttonCancel
                    .replace('{buttonCancelText}', params.buttonCancelText);
                params.domObjects.buttonCancel = $(template);
                params.domObjects.buttonCancel.appendTo(container);
            }
        },
        drawMask: function(container, params) {
            const $mask = methods.getMask(container, params);

            clearTimeout(params.imageFormTimeout);
            methods.hideImageForm(container, params);

            if (container.find('.js-cell.banners-grid__cell-wrapper_selected').length > 0) {
                const coords = methods.getCoords(container, params);
                $mask.show().width();
                $mask.css({
                    left: coords[0],
                    top: coords[1],
                    width: coords[2] - coords[0],
                    height: coords[3] - coords[1]
                });
                clearTimeout(params.maskTimeout);
                params.imageFormTimeout = setTimeout(function() {
                    methods.drawMediaForm(container, params);
                },500);
            } else {
                $mask.css({
                    width: 0,
                    height: 0
                });
                params.maskTimeout = setTimeout(function() {
                    methods.hideMask(container, params);
                },500);
            }
        },
        hideMask: function(container, params) {
            methods.getMask(container, params).hide();
        },
        drawMediaForm: function(container, params) {
            const $mask = methods.getMask(container, params),
                  $imageForm = methods.getImageForm(container, params),
                  maskTop = parseInt($mask.css('top'), 10),
                  maskLeft = parseInt($mask.css('left'), 10),
                  maskWidth = Math.round($mask.outerWidth()),
                  maskHeight = Math.round($mask.outerHeight());

            const size = maskWidth + 'px &times; '+maskHeight+'px';
            $imageForm.find('.placeholder').html(params.imageFormPlaceholder.replace('%size%', size))

            $imageForm.show().css({
                top: maskTop + 10,
                left: maskLeft + maskWidth / 2 - $imageForm.outerWidth() / 2
            });
        },
        drawMedia: function(container, params, src, fileId, mimeType) {
            let template = mimeType.indexOf('video') === -1
                ? params.templates.image
                : params.templates.video.replace('{type}', mimeType);
            template = template.replace('{fileId}', fileId)
                .replace('{src}', src)
                .replace('{changeLinkText}', params.changeLinkText);
            const $media = $(template);
            const $mask = methods.getMask(container, params);

            $media.css({
                width: $mask.outerWidth(),
                height: $mask.outerHeight(),
                left: parseInt($mask.css('left'), 10),
                top: parseInt($mask.css('top'), 10),
            });

            $media.appendTo(container);
        },
        hideImageForm: function(container, params) {
            methods.getImageForm(container, params).hide();
        },
        checkLastRow: function(container, params, counter) {
            if (!counter) counter = 1;

            if (params.checkingRowsProcess) {
                return false;
            }

            params.checkingRowsProcess = true;

            const countInRow = methods.getCountCellsInRow(container, params),
                  $allCells = params.domObjects.cellsBox.find('.js-cell'),
                  $lastSelected = $allCells.filter('.banners-grid__cell-wrapper_selected').last(),
                  rowHeight = methods.getRowHeight(container, params);
            let wasChanged = false;

            if (countInRow > 0) {
                //если выделенная ячейка на последней строке
                if ($lastSelected.length && $allCells.index($lastSelected) >= $allCells.length - countInRow) {
                    methods.appendRow(container, params);
                    wasChanged = true;
                }
                //или если на последней строке есть изображение
                else if (container.height() - methods.getImagesBottomLine(container, params) + params.cellMargin < rowHeight) {
                    methods.appendRow(container, params);
                    wasChanged = true;
                }
                else {
                    //если не первая строка
                    if ($allCells.length > countInRow) {
                        //если есть пустая невыделенная строка в конце
                        if ($lastSelected.length && $allCells.index($lastSelected) < $allCells.length - (countInRow * 2) ||
                            $lastSelected.length === 0 && $allCells.length > countInRow) {
                            if (methods.getImagesBottomLine(container, params) < container.height() - rowHeight) {
                                methods.removeRow(container, params);
                                wasChanged = true;
                            }
                        }
                    }
                }
            }

            if (wasChanged && counter < 10) {
                setTimeout(function() {
                    params.checkingRowsProcess = false;
                    methods.checkLastRow(container, params, ++counter);
                }, 500);
            } else {
                params.checkingRowsProcess = false;
            }
        },
        checkEmptyRows: function(container, params) {
            const rowHeight = methods.getRowHeight(container, params);

            const areIntersect = function (rect1, rect2) {
                return (rect1.top <= rect2.top) && (rect2.top <= rect1.bottom)
                    || (rect2.top <= rect1.top) && (rect1.top <= rect2.bottom);
            };

            const bannersRects = this.getData(container, params).map(banner => {
                return {
                    top: banner.top,
                    bottom: banner.top + banner.height,
                };
            });

            const rowsCount = this.getRowsCount(container, params);

            let emptyRows = [];
            for (let i = 0; i < rowsCount - 1; i++) {
                const rowRect = {
                    top: rowHeight * i + 18,
                    bottom: rowHeight * (i+1) + 8,
                };

                let isEmptyRow = true;
                bannersRects.forEach(bannerRect=> {
                    if (areIntersect(bannerRect, rowRect)) {
                        isEmptyRow = false;
                    }
                });

                if (isEmptyRow) {
                    emptyRows.push(i);
                }
            }

            emptyRows.reverse().forEach(row => {
                container.find('.js-media').each(function() {
                    const bannerTop = parseInt($(this).css('top'));
                    if (bannerTop >= rowHeight * row) {
                        $(this).css('top', `${ bannerTop - rowHeight }px`);
                    }
                });
            });
        },
        removeRow: function(container, params) {
            const countInRow = methods.getCountCellsInRow(container, params),
                  $allCells = params.domObjects.cellsBox.find('.js-cell');
            if (countInRow > 0) {
                for (let i = 0; i < countInRow; i++) {
                    $($allCells[$allCells.length - (i+1)]).remove();
                }
                const rowHeight = methods.getRowHeight(container, params);
                container.height(container.height() - rowHeight);
            }

            params.domObjects.cellsBox.find(`.banners-grid__add-row-button:last`).remove();
        },
        appendRow: function(container, params) {
            const countInRow = methods.getCountCellsInRow(container, params);
            if (countInRow > 0) {
                const rowHeight = methods.getRowHeight(container, params),
                      newContainerHeight = container.height() + rowHeight;

                container.height(newContainerHeight);

                setTimeout(function() {
                    for (let i = 0; i < countInRow; i++) {
                        methods.appendCell(container, params);
                    }
                }, 300);
            }

            this.appendButtonAddRow(container, params, this.getRowsCount(container, params));
        },
        insertRowBefore(container, params, beforeRow) {
            const countInRow = methods.getCountCellsInRow(container, params);
            const rowHeight = methods.getRowHeight(container, params);
            const newRowTop = beforeRow*rowHeight;

            if (countInRow > 0) {
                container.height(container.height() + rowHeight);

                setTimeout(function(){
                    for (let i = 0; i < countInRow; i++) {
                        methods.insertCellBefore(container, params, beforeRow*countInRow);
                    }
                }, 300);
            }

            container.find('.js-media').each(function(){
                const top = $(this).offset().top;

                if (top >= newRowTop) {
                    $(this).offset({top: top + rowHeight});
                }
            });

            this.appendButtonAddRow(container, params, this.getRowsCount(container, params));
        },
        appendCell: function(container, params) {
            const $cell = $(params.templates.cell);
            $cell.css({
                width: params.cellWidth,
                height: params.cellHeight,
                margin: params.cellMargin,
            });
            $cell.appendTo(params.domObjects.cellsBox);
        },
        insertCellBefore: function(container, params, after = 0){
            const $cell = $(params.templates.cell);
            $cell.css({
                width: params.cellWidth,
                height: params.cellHeight,
                margin: params.cellMargin,
            });
            $cell.insertBefore(params.domObjects.cellsBox.find(`.js-cell:eq(${ after })`));
        },
        clearSelection: function(container, params) {
            container.find('.js-cell.banners-grid__cell-wrapper_selected').removeClass('banners-grid__cell-wrapper_selected');
            methods.hideMask(container, params);
            methods.hideImageForm(container, params);
        },
        getImagesBottomLine: function(container, params, absolute) {
            let bottomLine = 0;
            container.find('.js-media').each(function() {
                const $image = $(this),
                    imageOffset = $image.offset(),
                    imageMarginTop = parseInt($image.css('marginTop'), 10),
                    imageMarginBottom = parseInt($image.css('marginBottom'), 10);
                let currentBottomLine = imageOffset.top + $image.outerHeight() + imageMarginTop + imageMarginBottom;

                if (!absolute) {
                    currentBottomLine -= container.offset().top;
                }

                if (currentBottomLine > bottomLine) {
                    bottomLine = currentBottomLine;
                }
            });

            return bottomLine;
        },
        getCountCellsInRow: function(container, params) {
            const width = params.domObjects.cellsBox.width();

            return Math.floor(width / (params.cellWidth + (params.cellMargin * 2)));
        },
        getRowHeight: function(container, params) {
            return params.cellHeight + (params.cellMargin * 2);
        },
        getMask: function(container, params) {
            if (params.domObjects.mask === null) {
                params.domObjects.mask = $(params.templates.mask).appendTo(container);
                params.domObjects.mask.css({
                    left: 0,
                    top: 0,
                    width: 0,
                    height: 0,
                });
            }
            return params.domObjects.mask;
        },
        getImageForm: function(container, params) {
            if (params.domObjects.imageForm === null) {
                const template = params.templates.imageForm
                    .replace('{load_url}', params.imageLoadUrl);

                params.domObjects.imageForm = $(template).appendTo(container);

                // noinspection JSUnresolvedFunction
                new Spinner({
                    lines: 10,
                    length: 6,
                    width: 3,
                    radius: 6,
                    scale: 1,
                    corners: 2,
                    color: 'rgb(103, 106, 108)',
                    opacity: 0.3,
                    rotate: 0,
                    direction: 1,
                    speed: 1,
                    trail: 60,
                    fps: 20,
                    zIndex: 100,
                    className: 'spinner',
                    top: '50%',
                    left: '50%',
                    shadow: false,
                    hwaccel: false,
                    position: 'absolute'
                }).spin(params.domObjects.imageForm.find('.js-loader')[0]);

                params.domObjects.imageForm.myLoadFile({
                    beforeLoad: function() {
                        const $inputWidth = params.domObjects.imageForm.find('[name="width"]'),
                              $inputHeight = params.domObjects.imageForm.find('[name="height"]'),
                              $mask = methods.getMask(container, params);

                        $inputWidth.val($mask.outerWidth());
                        $inputHeight.val($mask.outerHeight());

                        params.domObjects.imageForm.addClass('banners-grid__media-form_loading');
                        $('#bannersGrid').addClass('banners-grid_disabled');
                    },
                    afterLoad: function(data) {
                        $('#bannersGrid').removeClass('banners-grid_disabled');
                        if (data.file) {
                            methods.drawMedia(container, params, data.file.path, data.file.id, data.file.mimeType);
                            methods.clearSelection(container, params);
                        }
                        params.domObjects.imageForm.removeClass('banners-grid__media-form_loading');
                    },
                    onError: function() {
                        $('#bannersGrid').removeClass('banners-grid_disabled');
                        params.domObjects.imageForm.removeClass('banners-grid__media-form_loading');
                    }
                });
            }
            return params.domObjects.imageForm;
        },
        getData: function(container) {
            const data = [];
            container.find('.js-media').each(function() {
                const $image = $(this),
                      tmp = {
                        id: $image.data('id') || '',
                        fileId: $image.data('fileId'),
                        width: $image.width(),
                        height: $image.height(),
                        top: parseInt($image.css('top'), 10),
                        left: parseInt($image.css('left'), 10),
                        link: $image.find('input[name="link"]').val()
                    };
                data.push(tmp);
            });
            return data;
        },
        getCoords: function(container, params) {
            const containerOffset = params.domObjects.cellsBox.offset(),
                  parentPaddingTop = parseInt(container.css('paddingTop'), 10),
                  parentPaddingLeft = parseInt(container.css('paddingLeft'), 10),
                  parentTop = containerOffset.top,
                  parentLeft = containerOffset.left;
            let x1 = null, y1 = null, x2 = 0, y2 = 0,
                tmp = 0;

            container.find('.js-cell.banners-grid__cell-wrapper_selected').each(function() {
                const offset = $(this).offset(),
                      marginLeft = parseInt($(this).css('marginLeft'), 10),
                      marginTop = parseInt($(this).css('marginTop'), 10),
                      elWidth = $(this).outerWidth() + marginLeft + parseInt($(this).css('marginRight'), 10),
                      elHeight = $(this).outerHeight() + marginTop + parseInt($(this).css('marginBottom'), 10);

                tmp = offset.left - marginLeft - parentLeft;
                x1 = x1 === null || x1 > tmp ? tmp : x1;

                tmp = offset.top - marginTop - parentTop;
                y1 = y1 === null || y1 > tmp ? tmp : y1;

                tmp = offset.left - marginLeft + elWidth - parentLeft;
                x2 = x2 < tmp ? tmp : x2;

                tmp = offset.top - marginTop + elHeight - parentTop;
                y2 = y2 < tmp ? tmp : y2;
            });

            x1 = x1 + parentPaddingLeft;
            y1 = y1 + parentPaddingTop;
            x2 = x2 + parentPaddingLeft;
            y2 = y2 + parentPaddingTop;

            x1 = x1 + params.imagesMargin;
            y1 = y1 + params.imagesMargin;
            x2 = x2 - params.imagesMargin;
            y2 = y2 - params.imagesMargin;

            return [x1,y1,x2,y2];
        },
        getRowsCount(container, params) {
            const cellsCount = params.domObjects.cellsBox.find(`.js-cell`).length;
            const countInRow = methods.getCountCellsInRow(container, params);

            return Math.ceil(cellsCount / countInRow);
        },
        appendButtonAddRow(container, params, beforeRow) {
            const $btnAddRow = $(params.templates.buttonAddRow);
            const rowHeight = methods.getRowHeight(container, params);

            let buttonLocked = false;

            $btnAddRow.css('top', (beforeRow * rowHeight - 9) + 'px')
            $btnAddRow.click(event => {
                event.preventDefault();
                if (!buttonLocked) {
                    buttonLocked = true;
                    $('#bannersGrid').addClass('banners-grid_disabled');
                    setTimeout(() => {
                        buttonLocked = false;
                        $('#bannersGrid').removeClass('banners-grid_disabled')
                    }, 1000);
                    this.insertRowBefore(container, params, beforeRow);
                }
            });
            $btnAddRow.appendTo(params.domObjects.cellsBox);
        },
    };

    $[pluginName] = $.fn[pluginName] = function(method) {

        var container = typeof this === 'function' ? $(window) : $(this);

        if(!container || !container.length) return;

        //добавляем первые параметры: container, params
        var args = [container, container.data()[pluginName] && container.data()[pluginName].params];

        if (methods[method]) {

            if(!container.data()[pluginName] && method !== 'init'){
                methods.init(container, null, {})
            }

            args = [container, container.data()[pluginName] && container.data()[pluginName].params];

            //добавляет к аргументам те, которые были переданы
            args = args.concat(Array.prototype.slice.call(arguments, 1));
            return methods[method].apply(this, args);

        } else if ( typeof method === 'object' || !method ) {

            //добавляет к аргументам те, которые были переданы
            args = args.concat(arguments[0]);
            return methods.init.apply(this, args );

        } else if (method.split('.').length > 1) {

            var tmp = method.split('.'), i, target = methods;
            for(i = 0; i < tmp.length; i++){
                if(!target[tmp[i]]) break;
                target = target[tmp[i]];
            }
            //добавляет к аргументам те, которые были переданы
            args = args.concat(Array.prototype.slice.call(arguments, 1));
            return target.apply(this, args);

        } else {
            $.error( 'Метод с именем ' +  method + ' не существует для jQuery.' + pluginName );
        }

    };

})(jQuery);

/** $.extTable - модуль, реализующий вложенные таблицы на основе DataTables
 * */
(function($){

    "use strict";

    var pluginName = 'extTable';

    var console = myConsole(pluginName);

    var methods = {
        init: function(container, params, options) {

            console.log('init');

            params = $.extend(true, {
                clientDiscount: 0,
                additionalDiscount: {
                    type: 'none',
                    value: 0
                },
                $tableReceiver: null,
                data: $.cart('getData') || {},
                pageLength: 30,
                changedCallback: null,
                cartTable: false,
                stackChanges: {}, //здесь накапливаются изменения корзины до отправки на сервер, которая происходит с небольшой задержкой
                stackSets: {},
                storeKey: '',
                domObjects: {
                    $table: null,
                    $tableParentBox: null
                },
                lang: {
                    count_for_all: '',
                    products: '',
                    to_order: '',
                    to_order_for_all: '',
                    remove_from_order: '',
                    total: '',
                    remove_from_order_for_all: '',
                },
                footer: false,
                parent: {
                    dataTableParams: null,
                    filter: false,
                    ajaxData: function(data){return data},
                    onAjaxSuccess: function(data){return true}
                },
                templates: {
                    expandProducts: '<a href="#expandProducts">{lang.products}</a>',
                    toOrder: '<a href="#toOrder" class="to-order-button">{lang.to_order}</a>',
                    removeFromOrder: '<a href="#removeFromOrder" class="remove-from-order-button">{lang.remove_from_order}</a>',
                    expandButton: '<div class="expand-button"><i class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></div>',
                    expandRow: '<tr id="{subgridId}" class="subgrid-row"  style="display: none;">' +
                    '<td colspan="11" class=" subgrid-data">' +
                    '<div class="subgrid-container">' +
                    '<table id="{subgridTableId}" class="table table-bordered table-hover"></table>' +
                    '</div>' +
                    '</td>' +
                    '</tr>'
                }
            }, options);

            container.data()[pluginName] = {
                params: params
            };

            // $.fn.dataTable.ext.errMode = 'none';

            methods.translateTemplate(container, params);
            methods.setDomObjects(container, params);

            methods.initParentTable(container, params);
        },
        translateTemplate: function(container, params){
            for(var k in params.lang){
                for(var i in params.templates){
                    params.templates[i] = params.templates[i].replace(new RegExp("\{lang\."+k+"\}","g"), params.lang[k]);
                }
            }
        },
        setDomObjects: function(container, params){
            if(params.domObjects.$table === null) params.domObjects.$table = container;
            if(params.domObjects.$tableParentBox === null) params.domObjects.$tableParentBox = params.domObjects.$table.parents('.table-parent');

            params.storeKey = params.domObjects.$table.attr('id') + ':' + location.pathname;
        },
        getDTableInstance: function(container, params){
            return params.parentDataTable;
        },
        initParentTable: function(container, params){

            if(params.domObjects.$table.hasClass('dataTable')){

                if (!params.data || params.data.length === 0) {
                    params.domObjects.$tableParentBox.addClass('empty');
                    return false;
                } else {
                    params.domObjects.$tableParentBox.removeClass('empty');
                }

                var dt = params.domObjects.$table.dataTable();

                dt.fnClearTable();
                dt.fnAddData(params.data);

            } else {

                var initParams = {};

                if (params.parent.type === 'ajax') {
                    initParams = {
                        data: null,
                        serverSide: true,
                        processing: true,
                        ajax: {
                            url: params.parent.url,
                            type: "POST",
                            data: params.parent.ajaxData,
                            dataSrc: params.parent.onAjaxSuccess
                        }
                    };
                } else {

                    if (!params.data || params.data.length === 0) {
                        params.domObjects.$tableParentBox.addClass('empty');
                        return false;
                    }

                    initParams = {
                        data: params.data,
                        serverSide: false,
                        ajax: false
                    };
                }

                initParams = $.extend({}, CONFIG.DATATABLES, initParams);

                if (params.parent.dataTableParams) {
                    initParams = $.extend({}, initParams, params.dataTableParams);
                }

                if (params.footer) {
                    methods.createFooterIfNotExist(container, params);
                }

                var sortParentObj = store.get('table_parent_table_sort', [2, "asc"]);

                params.parentDataTable = params.domObjects.$table.DataTable($.extend({}, initParams, {
                    order: [sortParentObj],
                    fixedColumns: true,
                    pageLength: parseInt(params.pageLength, 10),
                    bLengthChange: true,
                    lengthMenu: [ 30, 60, 90, 300 ],
                    bFilter: false,
                    dom: 't<"table-bottom"<"table-bottom__main-info"ip>l>',
                    columns: params.parent.columns,
                    stateLoadCallback: function(settings) {
                        settings.oSavedState && (settings.oSavedState.length = null);
                        return settings;
                    },
                    createdRow: function (row, data, index) {
                        let modelSlug = data.slug;
                        if (!modelSlug) {
                            modelSlug = data.id;
                        }
                        $(row)
                            .attr('data-model-id', data.id).data('modelId', data.id)
                            .attr('data-model-slug', modelSlug).data('modelSlug', modelSlug)
                            .attr('data-gpQuantity', data.gpQuantity).data('gpQuantity', data.gpQuantity)
                            .attr('data-boxQuantity', data.boxQuantity).data('boxQuantity', data.boxQuantity)
                            .find('.expandProducts-th').html(params.templates.expandProducts).end()
                            .find('.expandButton-th').append(params.templates.expandButton);

                        $(row).find('.price-th, .totalCost-th').each(function(){
                            if($(this).text()){
                                if($(this).html().indexOf('<div') === -1) {
                                    $(this).text(number_format($(this).text()));
                                } else {
                                    $(this).html($(this).html());
                                }
                            }
                        });

                        var addClass = '';
                        if(data.availableCount < 1 && data.delayCount > 0){
                            addClass = 'in-order';
                        }
                        if(data.isNew){
                            addClass = ' new';
                        }
                        if(data.isSale){
                            addClass = ' sale';
                        }
                        if(data.isAccess){
                            addClass = ' hiddenPrice';
                        }
                        $(row).addClass(addClass);
                        $(row).addClass('expand-subgrid');

                        var $modelTh = $(row).find('.model-th');
                        if ($modelTh.length) {
                            if (data.isHonestSign) {
                                $modelTh.append('<div class="honest_sign"></div>');
                                $modelTh.addClass('is_honest_sign');
                            }
                        }
                    }
                }));

                params.domObjects.$tableParentBox.removeClass('empty');

                methods.setParentTableEvents(container, params);


                params.parentDataTable.on('draw.sort', function(e){
                    var currentOrder = params.parentDataTable.order();
                    if(currentOrder && currentOrder[0] && currentOrder[0].length){
                        currentOrder = currentOrder[0];
                        if(currentOrder[0] !== 2) { //если не дефолтная сортировка
                            store.set('table_parent_table_sort', currentOrder);
                        } else {
                            store.set('table_parent_table_sort', undefined);
                        }
                    }
                });

                if(params.parent.filter && params.parent.filter.length){
                    if(typeof yadcf !== 'undefined'){
                        methods.initFilters(container, params, params.parentDataTable);
                    }
                }
            }
        },
        initFilters: function(container, params, $table){
            yadcf.init($table, params.parent.filter);

            //функция, определяющая заполнен ли фильтр в текущей ячейке и выставляющая/убирающая класс filter-active на ячейку
            var changeFilter = function(){
                var $cell = $(this),
                    $filterInput = $cell.find('.yadcf-filter'),
                    $filterRangeInputs = $cell.find('.yadcf-filter-range');

                if($filterInput.length){
                    if($filterInput.val() && $filterInput.val() !== '-1'){
                        $cell.addClass('filter-active');
                    } else {
                        $cell.removeClass('filter-active');
                    }
                }
                if($filterRangeInputs.length){
                    var empty = true;
                    $filterRangeInputs.each(function () {
                        if($(this).val()){
                            empty = false;
                            return false;
                        }
                    });
                    if(!empty){
                        $cell.addClass('filter-active');
                    } else {
                        $cell.removeClass('filter-active');
                    }
                }
            };

            var $theadRow = params.domObjects.$table.find('thead tr');
            for (var i = 0; i < params.parent.filter.length; i++) {
                var filterCol = params.parent.filter[i];
                var $cell = $theadRow.find('th:eq('+filterCol.column_number+')'),
                    $filterButton = $('<i class="fa fa-filter show-filters-button"></i>');

                $cell.addClass('filter').append($filterButton);

                $filterButton.on('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();
                    params.domObjects.$table.toggleClass('show-filters');

                    //сохраняем в хранилище информацию об открытом фильтре
                    var tableStore = store.get(params.storeKey, {});
                    tableStore.showFilters = params.domObjects.$table.hasClass('show-filters');
                    store.set(params.storeKey, tableStore);
                });

                (function ($cell) {
                    $cell.on('change blur keyup', changeFilter);
                    $cell.find('.yadcf-filter-reset-button').on('click', function () {
                        $cell.removeClass('filter-active');
                    });
                    changeFilter.call($cell);
                })($cell);
            }

            if(store.get(params.storeKey, {}).showFilters){
                params.domObjects.$table.addClass('show-filters');
            }
        },
        setParentTableEvents: function(container, params){
            params.domObjects.$table
                .on('click', '.expand-subgrid', function(e){
                    e.preventDefault();
                    methods.expandRow(container, params, $(this).closest('tr'));
                })
                .on('click', '.to-order-button', function(e){
                    e.preventDefault();
                    methods.addButtonClick.call(this, container, params);
                })
                .on('click', '.remove-from-order-button', function(e){
                    e.preventDefault();
                    methods.removeButtonClick.call(this, container, params);
                })
                .on('blur', '[name=count]', function(){
                    var $input = $(this);
                    if(!$input.data('forAll')){
                        var val = $input.val(),
                            max = parseInt($input.attr('max'), 10);

                        if(val > max)
                            $input.val(max);
                        else if(val < 0)
                            $input.val(0);

                        $input.trigger('change');
                    }
                    if(!$(this).val()){
                        $(this).val(0);
                    }
                })
                .on('focus', 'input[name="count"]', function(e){
                    if($(this).val() < 1){
                        $(this).val('')
                    }
                });

            params.domObjects.$table.find('.fancybox-photo').fancybox({
                padding: 0,
                helpers: {
                    title: {
                        type: 'outside'
                    },
                    thumbs: {
                        // width: 50,
                        height: 50
                    },
                    overlay: {
                        locked: false
                    }
                },
                wrapCSS: "styled-fancybox background-white",
                afterClose: function (instance, slide) {
                    $('.showColor').each(function (i, e) {
                        var rel = $(this).attr('rel');
                        var newRel = rel.replace('_color', '');
                        $(this).attr('rel', newRel);
                        $(this).removeClass('showColor');
                    })

                }
            });

            //переключение фоток цветов при клике на название цвета у товара
            params.domObjects.$table
                .on('click', '[data-color-code]', function(e){

                    e.preventDefault();
                    if($(this).hasClass('no-change-color')) return false;

                    if($(e.target).closest('.color-th').length) {
                        var clickedColorCode = $(this).data('colorCode'),
                            $modelRow = $(this).closest('.subgrid-row').prev(),
                            $imagesWrapper = $modelRow.find('.images-th'),
                            $photos = $imagesWrapper.find('[data-color-codes]'),
                            modelId = $modelRow.data('modelId');
                        ;
                        if(clickedColorCode) {
                            $photos.each(function () {
                                var imgCodes = $(this).data('colorCodes');
                                if (imgCodes && imgCodes.length > 0 && $.inArray(clickedColorCode, imgCodes) !== -1){
                                    $(this).attr('rel', $(this).attr('rel') + '_color');
                                    $(this).addClass('showColor');
                                }
                            });
                            var $forOurCode = $photos.filter('[rel="model_'+modelId+'_photo_color"]:eq(0)');
                            if($forOurCode.length > 0){
                                $forOurCode.trigger('click');
                            }
                        }
                    }
                });
        },
        createFooterIfNotExist: function(container, params){
            var $table = params.domObjects.$table;
            var columns = params.parent.columns;

            if(!$table.find('tfoot').length) {
                var footer = document.createElement('tfoot');
                var tr = document.createElement('tr');

                for (var i = 0; i < columns.length; i++) {
                    var column = columns[i];
                    var th = document.createElement('th');
                    th.className = column.sClass;
                    tr.appendChild(th);
                }

                footer.appendChild(tr);
                $table.append(footer);
            }
        },
        expandRow: function(container, params, $parentRow){
            var parentRowId = $parentRow.attr('id'),
                subgridId = parentRowId + '_subgrid',
                subgridTableId = parentRowId + '_table',
                modelId = $parentRow.data('modelId'),
                modelSlug = $parentRow.data('modelSlug');

            var hiddenPrice = 0;
            if ($parentRow.hasClass('hiddenPrice')) {
                hiddenPrice = 1;
            }
            var $subgridRow = $("#"+subgridId);

            if(!$subgridRow.length){

                $subgridRow = $(params.templates.expandRow.replace('{subgridId}', subgridId).replace('{subgridTableId}', subgridTableId));

                $parentRow.after($subgridRow);

                $subgridRow.attr('data-model-id', modelId).data('modelId', modelId);

                if (hiddenPrice) {
                    $subgridRow.addClass('hiddenPrice');
                }

                var $subgridTable = $("#"+subgridTableId);

                var initParams = {},
                    products = [];

                if(params.child.type === 'ajax'){
                    initParams = {
                        data: null,
                        serverSide: false,
                        ajax: {
                            url:  params.child.url.replace('{modelSlug}',modelSlug),
                            data: params.parent.ajaxData,
                            type: "POST",
                        }
                    };
                } else {
                    products = [];

                    for (var i in params.data) {
                        if (modelId === params.data[i].id) {
                            products = params.data[i].products;
                        }
                    }

                    initParams = {
                        data: products,
                        serverSide: false,
                        ajax: false
                    };
                }

                initParams = $.extend({}, CONFIG.DATATABLES, initParams);

                const sortChildObj = store.get('table_child_table_sort', null);

                params.childDataTable = $subgridTable.DataTable($.extend({}, initParams, {
                    order: sortChildObj ? [sortChildObj] : [],
                    bPaginate: false,
                    bInfo: false,
                    bFilter: false,
                    fixedColumns: true,
                    columns: params.child.columns,
                    createdRow: function (row, data, index) {
                        var $countCell = $(row).find('.count-th'),
                            $availableCell = $(row).find('.available-th'),
                            count = parseInt($countCell.text(), 10);

                        if($availableCell.length){
                            //getAvailableIndicatorHtml объявлена в файле templates/Catalog/index.html.twig
                            $availableCell.html(getAvailableIndicatorHtml(data.countAvailable, data.countDelay, data.availableLimitDisable, data.storeParent));
                        }

                        if(data.countAvailable === 0 && data.countDelay === 0 && !data.availableLimitDisabled){
                            $(row).addClass('not-in-stock');
                        }

                        if(data.countAvailable === 0 && !data.availableLimitDisabled){
                            $(row).addClass('not-in-stock__available');
                        }

                        if(data.countDelay === 0){
                            $(row).addClass('not-in-stock__delay');
                        }

                        if(!params.child.editable) {
                            $countCell.html(count);
                        } else {

                            $(row).attr('data-product-id', data.id).data('productId', data.id)
                                .attr('data-gpQuantity', data.gpQuantity).data('gpQuantity', data.gpQuantity)
                                .attr('data-boxQuantity', data.boxQuantity).data('boxQuantity', data.boxQuantity);

                            if(data.colorCode){
                                $(row).attr('data-color-code', data.colorCode).data('colorCode', data.colorCode);
                                $(row).find('.color-th').addClass('pointer');
                            }



                            if(!params.$tableReceiver || data.count ||  data.availableLimitDisabled) {
                                $countCell.html(getCountBlockHtml(data.id, data.count, data.availableLimitDisabled));
                            }
                            else {
                                $countCell.html('');
                            }


                            if (!params.$tableReceiver) {
                                $(row).find('.toOrder-th').html(params.templates.removeFromOrder);
                                if (count) {
                                    $countCell.find('input').val(count).data('lastValue', count);
                                }
                            } else {
                                if(data.countAvailable || data.availableLimitDisabled) {
                                    $(row).find('.toOrder-th').html(params.templates.toOrder);
                                } else {
                                    $(row).find('.toOrder-th').html('');
                                }
                            }
                        }

                        $(row).find('.price-th, .totalCost-th').each(function(){
                            if($(this).text()){
                                if($(this).html().indexOf('<div') === -1) {
                                    $(this).text(number_format($(this).text()));
                                } else {
                                    $(this).html($(this).html());
                                }
                            }
                        });

                        if (hiddenPrice) {
                            $(row).find('.price-th, .available-th, .count-th').each(function(){
                                $(this).html('');
                            });
                        }
                    },

                }));
                var sizesPriority = [
                    'XXS',
                    'XS',
                    'S',
                    'M',
                    'L',
                    'XL',
                    'XXL',
                    'XXXL',
                ];
                var sizeSort = function (a, b) {

                    var s_aIndex = $.inArray(a, sizesPriority) !== -1;
                    var s_bIndex = $.inArray(b, sizesPriority) !== -1;
                    var aSizeSort = s_aIndex === false ? a.split(/[\s,\._\/\(\)\[\]\{\}-]+/) : ['_'+s_aIndex];
                    var bSizeSort = s_bIndex === false ? b.split(/[\s,\._\/\(\)\[\]\{\}-]+/) : ['_'+s_bIndex];

                    if (aSizeSort === bSizeSort) {
                        return 0;
                    }

                    if (!$.isArray(bSizeSort)) {
                        bSizeSort = [bSizeSort];
                    }
                    if (!$.isArray(aSizeSort)) {
                        aSizeSort = [aSizeSort];
                    }

                    for (var key = 0; key < aSizeSort.length; key++) {
                        if (typeof(aSizeSort[key]) !== 'undefined' && aSizeSort[key] !== null &&
                            typeof(bSizeSort[key]) !== 'undefined' && bSizeSort[key] !== null &&
                            aSizeSort[key] !== bSizeSort[key]) {
                            return ((Number(aSizeSort[key]) < Number(bSizeSort[key])) ? -1 : ((Number(aSizeSort[key]) > Number(bSizeSort[key])) ? 1 : 0));
                        }
                    }

                    return ((Number(aSizeSort[0]) < Number(bSizeSort[0])) ? -1 : ((Number(aSizeSort[0]) > Number(bSizeSort[0])) ? 1 : 0));
                };

                var colorsPriority = [
                    'bianco',
                    'natural',
                    'beige',
                    'bronz',
                    'shade',
                    'grafit',
                    'fumo',
                    'mocca',
                    'nero',
                ];
                var colorSort = function (a, b) {
                    var aIndex = $.inArray(a, colorsPriority) !== -1;
                    var bIndex = $.inArray(b, colorsPriority) !== -1;
                    var aColorSort = aIndex === false ? a : ['_'+aIndex];
                    var bColorSort = bIndex === false ? b : ['_'+bIndex];

                    return ((Number(aColorSort) < Number(bColorSort)) ? -1 : ((Number(aColorSort) > Number(bColorSort)) ? 1 : 0));
                };

                $.extend( $.fn.dataTableExt.oSort, {
                    "size-sort-asc": function ( a, b ) {
                        return sizeSort(a, b);
                    },
                    "size-sort-desc": function ( a, b ) {
                        return sizeSort(b, a);
                    },
                    "color-sort-asc": function ( a, b ) {
                        return colorSort(a, b);
                    },
                    "color-sort-desc": function ( a, b ) {
                        return colorSort(b, a);
                    }
                });

                params.childDataTable.on('draw.sort', function(e){
                    var currentOrder = params.childDataTable.order();
                    if(currentOrder && currentOrder[0] && currentOrder[0].length){
                        currentOrder = currentOrder[0];
                        if(currentOrder[0] !== 0) { //если не дефолтная сортировка
                            store.set('table_child_table_sort', currentOrder);
                        } else {
                            store.set('table_child_table_sort', undefined);
                        }
                    }
                });

                if(params.child.type !== 'ajax') {

                    $subgridRow.addClass('loaded');

                    methods.subgridToggle(container, params, $subgridRow);

                    if(params.child.editable) {
                        methods.initChildTable(container, params, $subgridTable);
                    }
                } else {

                    var expandTd = $parentRow.find('.expandProducts-th');

                    expandTd.find('a').hide();

                    // noinspection JSUnresolvedFunction
                    new Spinner({
                        lines: 9,
                        length: 0,
                        width: 7,
                        radius: 10,
                        scale: 0.8,
                        corners: 1,
                        color: '#E32B2D',
                        opacity: 0.2,
                        rotate: 0,
                        direction: 1,
                        speed: 1,
                        trail: 60,
                        fps: 20,
                        zIndex: 100,
                        className: 'spinner',
                        top: '46%',
                        left: '30%',
                        shadow: false,
                        hwaccel: false,
                        position: 'absolute'
                    }).spin(expandTd[0]);

                    params.childDataTable.one('draw.dt', function (){
                        $subgridRow.addClass('loaded');

                        expandTd.find('a').show().end().find('.spinner').remove();

                        methods.subgridToggle(container, params, $subgridRow);

                        if(params.child.editable)
                            methods.initChildTable(container, params, $subgridTable);
                    });
                }

            } else {
                methods.subgridToggle(container, params, $subgridRow);
            }
        },
        subgridToggle: function(container, params, $subgridRow){
            var $parentRow = $subgridRow.prev();
            if($subgridRow.hasClass('loaded')) {
                if ($subgridRow.is(':visible')) {
                    $subgridRow.hide();
                    $parentRow.removeClass('expanded');
                } else {
                    $subgridRow.show();
                    $parentRow.addClass('expanded');
                }
            }
        },
        initChildTable: function(container, params, $table){
            if(params.$tableReceiver) {

                //region общий селектор количества
                if($table.find('tbody').find('[name="count"]').length) {
                    var $countTh = $table.find('thead').find('.count-th'),
                        $countForAll = $countTh
                                .html(getCountBlockHtml(0, 0, true))
                                .find('[name=count]');

                    $countForAll
                        .attr('max', 99999)
                        .attr('data-for-all', 1).data('forAll', 1)
                        .on('change keyup mousewheel', function () {
                            methods.changeAllCounts.call(this, container, params);
                        });

                    $countTh.on('click', '.count-selector-button', function () {
                        setTimeout(function () {
                            $countForAll.trigger('change');
                        }, 20);
                    });

                    if($countTh.find('.quantity-switcher').length === 0) {
                        var $parentRow = $table.closest('.subgrid-row').prev(),
                            gpQuantity = $parentRow.data('gpQuantity'),
                            boxQuantity = $parentRow.data('boxQuantity'),
                            switcherHtml = window.getCountSwitcherHtml(gpQuantity, boxQuantity);

                        $countTh.prepend(switcherHtml);
                    }

                    $table.find('tbody')
                        .on('click', '.count-selector-button', function(e) {
                            countChangeClick($(this));
                        });

                } else {
                    $table.find('thead').find('.count-th').html('');
                }
                //endregion

                //region общая кнопка "в заказ"
                var $toOrderAllButton = $(params.templates.toOrder).attr({
                    'data-toggle': 'tooltip',
                    'data-placement': 'left',
                    'data-for-all': 1,
                    'title': params.lang.to_order_for_all
                });

                $table.find('thead').find('.toOrder-th').html($toOrderAllButton);

                $toOrderAllButton.tooltip({container: "body"});
                //endregion

                //region здесь магия. убираем подчеркивание и pointer с названий цветов товаров, для которых нет фотографий
                //т.е. ищем среди фотографий фотки с таким колорКодом, если их нет - убираем классы уц нашего цвета,
                //чтобы не выглядел таким, что по нему можно тыкать. на бекенде реализовать было сложнее.
                $table.find('[data-color-code]').each(function(){
                    var colorCode = $(this).data('colorCode'),
                        $modelRow = $(this).closest('.subgrid-row').prev(),
                        $photos = $modelRow.find('.images-th').find('[data-color-codes]'),
                        notAvailable = true;
                    $photos.each(function () {
                        var imgCodes = $(this).data('colorCodes');
                        if (imgCodes && imgCodes.length > 0 && $.inArray(colorCode, imgCodes) !== -1){
                            notAvailable = false;
                            return false;
                        }
                    });
                    if(notAvailable){
                        $(this)
                            .find('.dashed-gray').removeClass('dashed-gray')
                            .parent().removeClass('pointer')
                            .closest('[data-color-code]').addClass('no-change-color');
                    }
                });
                //endregion

            } else {

                //region общая кнопка "удалить"
                var $removeAllButton = $(params.templates.removeFromOrder).attr({
                    'data-toggle': 'tooltip',
                    'data-placement': 'left',
                    'data-for-all': 1,
                    'title': params.lang.remove_from_order_for_all
                });

                $table.find('thead').find('.toOrder-th').html($removeAllButton);

                $removeAllButton.tooltip({container: "body"});
                //endregion

                $table.on('change', '[name=count]', function(){
                    var $input = $(this),
                        max = $input.attr('max') || 0,
                        val = parseInt($input.val(), 10),
                        lastVal = $input.data('lastValue');

                    if(val < 0)
                        val = 0;
                    else if(val > max)
                        val = max;

                    if(val !== lastVal){
                        var $tr = $(this).parents('[data-product-id]'),
                            productId = $tr.data('productId'),
                            $modelRow = $tr.parents('[data-model-id]').prev(),
                            modelData = params.parentDataTable.row($modelRow[0]).data(),
                            productData = params.childDataTable.row($tr[0]).data();

                        $input.data('lastValue', val);

                        methods.cart.set(container, params, modelData, productData, val);

                        $input.closest('tr').removeClass('not-enough');
                    }
                });

            }
        },
        changeAllCounts: function(c){
            var $input = $(this);
            //setTimeout костыль: при кручении мышью, событие, зачастую, вызывается раньше изменения value инпута
            setTimeout(function(){
                $input
                    .closest('.table')
                    .find('tbody')
                    .find('[name="count"]')
                    .val($input.val())
                    .trigger('blur');
            },0)
        },
        addButtonClick: function(container, params){
            var $this = $(this),
                $tr = $(this).parents('[data-product-id]'),
                $input = $tr.find('[name=count]');

            if($this.data('forAll')){
                $this.closest('.table')
                    .find('tbody')
                    .find('.to-order-button')
                    .trigger('click');
            } else {
                var productId = $tr.data('productId'),
                    count = parseInt($input.val(), 10),
                    $modelRow = $tr.parents('[data-model-id]').prev(),
                    modelData = params.parentDataTable.row($modelRow[0]).data(),
                    productData = params.childDataTable.row($tr[0]).data();

                if(params.$tableReceiver){
                    params.$tableReceiver.extTable('cart.add', modelData, productData, count);
                } else {
                    methods.cart.add(container, params, modelData, productData, count);
                }
            }
        },
        removeButtonClick: function(container, params){
            var $this = $(this);

            if($this.data('forAll')){
                $this.closest('.table')
                    .find('tbody')
                    .find('.remove-from-order-button')
                    .trigger('click');
            } else {
                var $tr = $(this).parents('[data-product-id]'),
                    productId = $tr.data('productId'),
                    $modelRow = $tr.parents('[data-model-id]').prev(),
                    modelData = params.parentDataTable.row($modelRow[0]).data(),
                    productData = params.childDataTable.row($tr[0]).data();

                if(params.$tableReceiver){
                    params.$tableReceiver.extTable('cart.set', modelData, productData, 0, true);
                } else {
                    methods.cart.set(container, params, modelData, productData, 0, true);
                }
            }
        },
        getData: function(container, params){
            return params.data;
        },
        callChangedCallback: function(container, params){
            if(params.changedCallback && typeof params.changedCallback === 'function'){
                params.changedCallback();
            }
        },
        cart: {
            findElementInData: function(data, id){
                for(var i = 0; i < data.length; i++){
                    if(data[i].id === id) return data[i];
                }
                return false;
            },
            getProductFromMemory: function(container, params, modelData, productData){
                var productId = productData.id,
                    modelId = modelData.id;

                var existModel = methods.cart.findElementInData(params.data, modelId);

                if(!existModel){
                    if(!modelData.totalCost){
                        modelData.totalCost = 0;
                        modelData.count = 0;
                        productData.totalCost = 0;
                    }
                    if(modelData.DT_RowId){
                        modelData.DT_RowId = modelData.DT_RowId + '_child';
                    }
                    params.data.push(modelData);
                    existModel = modelData;
                }

                if(!existModel.products){
                    existModel.products = [];
                }

                var existProduct = methods.cart.findElementInData(existModel.products, productId);

                if(!existProduct){
                    if(productData.DT_RowId){
                        productData.DT_RowId = productData.DT_RowId + '_child';
                    }
                    existModel.products.push(productData);
                    existProduct = productData;
                }

                if(!existProduct.count){
                    existProduct.count = 0;
                }

                return existProduct;
            },
            add: function(container, params, modelData, productData, count){
                count = parseInt(count, 10);

                if(!count || !modelData || !productData){
                    return false;
                }

                var existProduct = methods.cart.getProductFromMemory(container, params, modelData, productData);

                existProduct.count += count;

                //TODO: добавить к товару пометку актуального кол-ва

                $.cart('add', productData.id, count, function(){
                    methods.initParentTable(container, params);
                });

                methods.cart.addAnimation(container, params);

                if(!params.cartTable) {
                    methods.initParentTable(container, params);
                    methods.cart.recalculateSum(container, params);
                }

                methods.callChangedCallback(container, params);
            },
            set: function(container, params, modelData, productData, count, fromRemoveButton, suppressReload){
                count = parseInt(count, 10);

                var syncCallback;
                if(suppressReload === null){
                    syncCallback = function(){
                        methods.cart.recalculateSum(container, params);
                    };
                } else {
                    syncCallback = function(){
                        methods.initParentTable(container, params);
                    };
                }

                if(isNaN(count)){
                    return false;
                } else if(count < 0){
                    count = 0;
                }

                var existProduct = methods.cart.getProductFromMemory(container, params, modelData, productData);

                existProduct.count = count;

                //TODO: добавить к товару пометку актуального кол-ва

                $.cart('set', productData.id, count, syncCallback);

                if(!params.cartTable)
                    methods.cart.recalculateSum(container, params);

                // if(existProduct.count === 0) {
                if(fromRemoveButton) {
                    methods.cart.removeProductRow(container, params, productData.id);
                }

                methods.callChangedCallback(container, params);
            },
            removeProductRow: function(container, params, productId){
                params.domObjects.$table.find('[data-product-id="' + productId + '"]').fadeOut(function(){
                    var $modelRow = $(this).parents('[data-model-id]');
                    $(this).remove();
                    if(!$modelRow.find('tr[data-product-id]').length){
                        methods.cart.removeModelRow(container, params, $modelRow.data('modelId'));
                    }
                });

                methods.callChangedCallback(container, params);
            },
            removeModelRow: function(container, params, modelId){
                if(modelId) {
                    params.domObjects.$table.find('[data-model-id="' + modelId + '"]').fadeOut(function(){
                        $(this).remove();
                    })
                }
            },
            recalculateSum: function(container, params){

                var data = params.data,
                    totalCost = 0,
                    totalCount = 0;

                for (var i = 0; i < data.length; i++) {
                    var model = data[i],
                        $modelRow = $('#' + model.DT_RowId),
                        totalModelCount = 0,
                        totalModelCost = 0;

                    if($modelRow.length){
                        for (var j = 0; j < model.products.length; j++) {
                            var product = model.products[j],
                                $productRow = $('#' + product.DT_RowId);

                            product.count = parseInt(product.count, 10);
                            product.price = parseFloat(product.price);
                            product.totalCost = product.count * product.price;

                            totalModelCount += product.count;
                            totalModelCost += product.totalCost;

                            if($productRow.length){
                                $productRow.children('.count-th').find('input').val(product.count);
                                $productRow.children('.countAvailable-th').text(product.countAvailable);
                                $productRow.children('.totalCost-th').text(number_format(product.totalCost));
                            }
                        }
                    }

                    $modelRow.children('.count-th').text(totalModelCount);
                    $modelRow.children('.totalCost-th').text(number_format(totalModelCost));

                    totalCount += totalModelCount;
                    totalCost += totalModelCost;
                }

                var $tFoot = params.domObjects.$table.find('tfoot');

                totalCount = totalCount % 1 === 0 ? totalCount : totalCount.toFixed(2);
                totalCost = totalCost % 1 === 0 ? totalCost : totalCost.toFixed(2);

                $tFoot.find('.count-th').text(totalCount);
                $tFoot.find('.totalCost-th').text(number_format(totalCost));

                if(params.clientDiscount){
                    totalCost = methods.cart.discountingAmount(totalCost, params.clientDiscount);
                    $("#discountedAmount").text(totalCost);
                }
                if(params.additionalDiscount && params.additionalDiscount.type !== 'none' && params.additionalDiscount.value){
                    totalCost = methods.cart.discountingAmount(totalCost, params.additionalDiscount.value, params.additionalDiscount.type);
                    $("#discountedAmount").text(totalCost);
                }

            },
            addAnimation: function(container, params){
                var $productsAddToOrderAnimation = $("#productsAddToOrderAnimation");

                if($productsAddToOrderAnimation.is(':visible')){
                    $productsAddToOrderAnimation.hide()
                        .width();
                }

                $productsAddToOrderAnimation.show();

                clearTimeout(params.animationTimer);

                params.animationTimer = setTimeout(function(){
                    $productsAddToOrderAnimation.hide();
                }, 1000);
            },
            /**
             *
             * @param amount
             * @param discount
             * @param discountType "fixed" or "percents" or "none"
             * @returns {*}
             */
            discountingAmount: function(amount, discount, discountType){
                if(discountType === 'fixed'){
                    return number_format(amount - discount);
                } else {
                    return number_format(amount - (discount / 100 * amount));
                }
            },
        }
    };

    $[pluginName] = $.fn[pluginName] = function(method) {

        var container = $(this);
        //добавляем первые параметры: container, params
        var args = [container, container.data()[pluginName] && container.data()[pluginName].params];

        if (methods[method]) {

            if(!container.data()[pluginName] && method !== 'init'){
                methods.init(container, null, {})
            }

            //добавляет к аргументам те, которые были переданы
            args = args.concat(Array.prototype.slice.call(arguments, 1));
            return methods[method].apply(this, args);

        } else if ( typeof method === 'object' || !method ) {

            //добавляет к аргументам те, которые были переданы
            args = args.concat(arguments[0]);
            return methods.init.apply(this, args );

        } else if (method.split('.').length > 1) {

            var tmp = method.split('.'), i, target = methods;
            for(i = 0; i < tmp.length; i++){
                if(!target[tmp[i]]) break;
                target = target[tmp[i]];
            }
            //добавляет к аргументам те, которые были переданы
            args = args.concat(Array.prototype.slice.call(arguments, 1));
            return target.apply(this, args);

        } else {
            $.error( 'Метод с именем ' +  method + ' не существует для jQuery.' + pluginName );
        }

    };

})(jQuery);

/** finds the intersection of
 * two arrays in a simple fashion.
 *
 * PARAMS
 *  a - first array, must already be sorted
 *  b - second array, must already be sorted
 *
 * NOTES
 *
 *  Should have O(n) operations, where n is
 *    n = MIN(a.length(), b.length())
 */
var arrayIntersect = function(a, b){
    var d1 = {};
    var d2 = {};
    var results = [];
    for (var i = 0; i < a.length; i++) {
        d1[a[i]] = true;
    }
    for (var j = 0; j < b.length; j++) {
        d2[b[j]] = true;
    }
    for (var k in d1) {
        if (d2[k])
            results.push(k);
    }
    return results;
};